<template>
  <div v-if="hasLogin" class="container">
    <template v-if="vipData" >
      <div class="header">
        <Avatar class="u-avatar" shape="square" size="128" icon="ios-person" :src="userAvatar"/>
        <div class="user-info">
          <div class="name__panel">
            <div>
              <div class="name">{{userInfo.username}}</div>
              <div class="layout-row">
                <div class="user_des">{{userInfo.vip_info.name}}</div>
                <div v-if="userInfo.vip_info.expiry_date" style="margin-left: 15px;">
                  <span>有效期至：</span>
                  <span>{{userInfo.vip_info.expiry_date}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="systemSetting && systemSetting.software_intro" class="welcome">{{ systemSetting.software_intro }}</div>
          <div v-else class="welcome">欢迎成为{{ systemSetting && systemSetting.title ? systemSetting.title : '类众' }}Vip会员</div>
        </div>
      </div>
      <div class="intro-box">
        <div class="intro-item">
          <div class="title">普通会员</div>
          <div class="intro">{{ systemSetting && systemSetting.free_member_desc ? systemSetting.free_member_desc : '无介绍' }}</div>
        </div>
        <div class="intro-item" v-for="(item, index) in vipData" :key="index">
          <div class="title">{{ item.name }}</div>
          <div class="intro">{{ item.description ? item.description : '无介绍' }}</div>
        </div>
      </div>
      <div class="confirm-box">
        <Button :disabled="isShowNext" class="confirm-btn" @click="isShowNext = true" type="primary">已阅读以上内容，点击进行下一步充值</Button><!-- v-if="!isShowNext"  -->
      </div>

      <Menu v-if="isShowNext" class="menu" mode="horizontal" theme="light" :active-name="selectedVip" @on-select="onSelectMenu">
        <MenuItem v-for="(item, index) in vipData" :key="item.level" :name="index">{{item.name}}</MenuItem>
      </Menu>
      <!-- <div class="member__des">{{vipDescription}}</div> -->
      <div v-if="isShowNext" class="price__panel">
        <div v-for="(item, index) in priceList" :key="item.id" :class="['item', {'active' : index == selItem}]" @click="onClickItem(index)">
          <div class="title">{{item.title}}</div>
          <div class="price">{{item.price}}</div>
        </div>
      </div>
      <div v-show="payPrice && isShowNext" class="pay__panel">
        <template v-if="qrCode">
          <div class="qrcode-box" ref="qrcode"></div>
          <div class="right">
            <div class="price">{{payPrice}}</div>
            <img class="pay_logo" :src="payLogo" />
            <div class="tip">使用微信扫码支付，支付完请稍等片刻！</div>
          </div>
        </template>
        <div v-else-if="qrErrMsg">{{qrErrMsg}}</div>
      </div>
    </template>
  </div>
  <div v-else class="content__err">
    <a @click="onLogin">请先登录</a>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { getVipPayQr, checkTransaction } from '@/api/user'
import { getVipPriceData, getSystemSetting } from '@/api/setting'
import {oneOf} from '@/libs/tools.js'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Member',
  data() {
    return {
      payLogo: require('@/assets/images/wechat_pay.png'),

      selectedVip: 0,
      selItem: 0,
      vipData: null,

      qrCode: null,
      tradeNo: null,
      qrErrMsg: null,

      systemSetting: null,
      isShowNext: false
    }
  },
  computed: {
    ...mapGetters(['userAvatar', 'userInfo', 'hasLogin']),
    vipLevel() {
      if (this.$route.params) {
        const level = parseInt(this.$route.params.level)
        if (oneOf(level, [1, 2])) {
          return level
        }
      }
      return 0
    },
    priceList() {
      const len = this.vipData.length
      if (this.selectedVip < len) {
        return this.vipData[this.selectedVip].items
      }
      return null
    },
    vipDescription() {
      const len = this.vipData.length
      if (this.selectedVip < len) {
        return this.vipData[this.selectedVip].description
      }
      return null
    },
    payPrice() {
      if (this.priceList) {
        return this.priceList[this.selItem].price
      }
      return null
    },
    payItemId() {
      if (this.priceList) {
        return this.priceList[this.selItem].id
      }
      return null
    }
  },
  watch: {
    hasLogin(newVal) {
      if (newVal) {
        this.getVipPriceData()
      } else {
        Object.assign(this.$data, this.$options.data.call(this))
      }
    },
    selItem() {
      this.qrCode = null
      this.requestPayQr()
    },
    qrCode(newVal) {
      if (this.$refs.qrcode) {
        this.$refs.qrcode.innerHTML = ''
      }
      if (newVal != null) {
        this.$nextTick(() => {
          this.showQRcode()
        })
      }
    }
  },
  mounted() {
    this.getVipPriceData()
    this.initData()
  },
  beforeDestroy() {
    if (this.timers) {
      clearInterval(this.timers)
    }
  },
  methods: {
    ...mapMutations(['showLoginDialog']),
    async initData() {
      await this.getSystemSetting()
    },
    async getSystemSetting() {
      const res = await getSystemSetting()
      this.systemSetting = res.content
    },
    onLogin() {
      this.showLoginDialog()
    },
    showQRcode() {
      const _ = new QRCode(this.$refs.qrcode, {
        text: this.qrCode, // 需要转换为二维码的内容
        width: 160,
        height: 160,
        colorDark: '#000',
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      })

      if (this.timers) {
        clearInterval(this.timers)
      }

      this.timers = setInterval(async () => {
        await this.getOrderState()
      }, 3000)
    },
    async getVipPriceData() {
      try {
        const res = await getVipPriceData()
        this.vipData = res.content
        if (this.vipLevel == 2) {
          this.selectedVip = 1
        }
        this.requestPayQr()
      } catch (error) {
        this.$Message.error(error.message)
      }
    },
    async requestPayQr() {
      if (this.payItemId == null) return
      this.$Spin.show()
      this.qrErrMsg = null
      try {
        const params = {product_id: this.payItemId}
        const res = await getVipPayQr(params)
        this.tradeNo = res.content.trade_no
        this.qrCode = res.content.code_url
        this.$Spin.hide()
      } catch (error) {
        this.$Message.error(error.message)
        this.qrCode = null
        this.qrErrMsg = error.message
        this.$Spin.hide()
      }
    },
    async getOrderState() {
      if (this.tradeNo == null) return
      try {
        const params = {trade_no: this.tradeNo}
        await checkTransaction(params)
        this.$Message.success('支付成功')
        this.$router.push({name: 'user'})
      } catch (error) {
      }
    },
    onSelectMenu(name) {
      this.selectedVip = parseInt(name)
      this.selItem = 0
    },
    onClickItem(index) {
      this.selItem = index
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  margin: 20px auto;
  padding-bottom: 40px;
}

.content__err {
  padding: 60px 0;
  width: fit-content;
  margin: 0 auto;
}

.header {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  .user-info {
    margin-left: 20px;
    flex: 1;
    .welcome {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
      margin-right: 148px;
    }
  }
}

.name__panel {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #efefef;
  .name {
    font-size: 28px;
    line-height: 40px;
    color: #000
  }
  .user_des {
    color: #c00;
    border: solid 1px #c00;
    padding: 0 5px;
  }
}

.intro-box {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .intro-item {
    width: 270px;
    border: solid 2px #eee;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin-right: 20px;
    text-align: center;
    min-height: 300px;

    &:last-child {
      margin-right: 0;
    }
    .title {
      font-size: 22px;
      font-weight: 700;
      padding: 10px 0;
      border-bottom: 1px solid #f0f0f0;
    }
    .intro {
      font-size: 16px;
      padding: 10px 0;
    }
  }
}
.confirm-box {
  text-align: center;
  padding: 20px 0;

  .confirm-btn {

  }
}

.menu {
  display: flex;
  justify-content: center;

  border-top: 1px solid #e0e0e0;
  margin-top: 20px;
  .ivu-menu-item {
    margin: 0 10px;
    font-size: 22px;

  }
}

.member__des {
  text-align: center;
  font-size: 15px;
  padding: 40px 20px;
}

.price__panel {
  position: relative;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  .item {
    position: relative;
    width: 160px;
    height: 160px;
    margin: 10px;
    border-radius: 10px;
    background-color: #fff;
    border: solid 2px #eee;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      border-color: #ff3a3a;
      position: relative;

      &::before {
        content: '√';
        font-family: 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        position: absolute;
        top: -10px;
        right: -10px;
        color: #ff3a3a;
        font-size: 18px;
        font-weight: 700;
        padding: 0 10px;
        background-color: #fff;
        border: 2px solid #ff3a3a;
        border-radius: 5px;
      }
    }
    .title {
      width: 100%;
      text-align: center;
      color: #000;
      font-size: 17px;
      font-weight: 700;
    }
    .price {
      margin-top: 10px;
      width: 100%;
      text-align: center;
      color: #ff3a3a;
      font-size: 30px;
      font-weight: 500;
    }
    .price::before {
      content: "¥ ";
      font-size: 20px;
    }
  }
}

.pay__panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  .right {
    display: flex;
    height: 160px;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    .price {
      color: #ff3a3a;
      text-align: center;
      font-size: 40px;
      line-height: 60px;
      font-weight: 500;
      &::after {
        content: " 元";
        font-size: 14px;
      }
    }
    .pay_logo {
      flex-shrink: 0;
      width: 120px;
      height: 40px;
      object-fit: contain;
    }
    .tip {
      line-height: 40px;
      font-size: 15px;
    }
  }
  .err {
    text-align: center;
  }
}

.qrcode-box {
  display: inline-block;
  width: 160px;
  height: 160px;
}

</style>
